//BRAND
export const name = 'CertiPartner';
export const company = 'JisExpert Solutions';
export const websiteURL = 'https://certipartner.com';
export const serverURL = 'https://certipartner.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/certipartner.appspot.com/o/CertiPartner.png?alt=media&token=b284eb70-32da-4d8d-a010-3eb179cb8d59';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = false;
export const paystackEnabled = true;

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 1.95;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 19;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator revolutionized my content creation process, providing accurate and relevant topics effortlessly. It's a time-saving powerhouse that enhances the quality and relevance of my courses. A must-have tool for educators seeking efficiency and impactful online learning experiences.";
export const from = "Anam Meena Sharif";
export const profession = 'CFO at Spacester';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_scyfjrw9p3rlh6r";
export const paystackPlanIdTwo = "PLN_ivnenb3l2daimwe";
export const amountInZarOne = '2000';
export const amountInZarTwo = '19500';